import Tabs from "@shared/Tabs/Tabs.vue"
import { bookmarkQuery } from '@services/Filter.api'
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
    name:"FilterChipsContainer",

    components: {
        Tabs,
    },

    props: {
        data: {
            type: Object,
            default() { return {} },
            required: true
        }
    },

    data() {
        return {
            displayTabNames: [],
            filterChipsData: {},
            // showSearchWithinTab: false,

            toggleSaveQueryButton: false,
            queryName: "",
            saveQueryRules: [
                value => !!value || 'Required.'
            ],

            mapName: {
                date: "التاريخ",
                // order: "التسلسل",
                // sortBy: "الترتيب",
                // searchWithin: "البحث ضمن",
                subject: "الموضوع",
                location: "المكان",
                person: "الأشخاص",
                organization: "المؤسسات",
                source: "المصدر",
                author: "المؤلف",
                photographer: "المصور",
                category: "الإصدار",
                type: "النوع",
                topic: "التبویب"
            },

            tabNames: [
                'date', 
                // 'order', 
                // 'sortBy', 
                // 'searchWithin', 
                'subject', 
                'location', 
                'person', 
                'organization', 
                'source', 
                'author', 
                'photographer', 
                'category',
                'type',
                'topic'
            ],

            // mapSearchWithin: {
            //     title:"العنوان",
            //     keywords:"الكلمات المفتاح",
            //     content:"نص الموضوع"
            // }
        }
    },

    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
        ...mapState('auth', ['user']),
    },

    methods: {
        ...mapMutations('notification', ['notify']),

        removeUnneededQueries() {
            // console.log('removeUnneededQueries');
        },

        close(type, item) {
            // console.log('close', type, item);
            this.$emit('remove', type, item)
        },

        setDisplayTabNames() {
            if (this.filterChipsData) {
                if (this.filterChipsData.fromDate || this.filterChipsData.toDate) {
                    this.displayTabNames.push(this.mapName['date'])
                }

                for (let i = 1; i < this.tabNames.length; i++) {
                    var tabName = this.tabNames[i]
                    if (Object.hasOwnProperty.call(this.filterChipsData, tabName)) {

                        // if (tabName === 'searchWithin') {
                        //     if (this.filterChipsData[tabName].length == 1 && this.filterChipsData[tabName][0] == 'title') {
                        //         this.showSearchWithinTab = false
                        //     } else {
                        //         this.showSearchWithinTab = true
                        //         this.displayTabNames.push(this.mapName[tabName])
                        //     }
                        // } else {
                            this.displayTabNames.push(this.mapName[tabName])
                        // }
                    }
                }
            }
        },

        async saveQuery() {
            if (this.isLoggedIn) {
                if (this.queryName) {
                    var data = {
                        userId: this.user.id,
                        name: this.queryName,
                        query: this.getFormattedQuery(this.$route.query)
                        // query: this.$route.fullPath.split('?')[1]
                    }

                    await bookmarkQuery(data).then(
                        () => {
                            this.notify({message: 'query saved', show: true})
                        }, () => {
                            this.notify({message: 'A query with the same name has been saved, please change the name.', show: true})
                        }
                    )
                    this.toggleSaveQueryButton = false
                    this.queryName = ""
                } else {
                    this.notify({message: 'Please type in a name for the query', show: true})
                }
            } else {
                this.notify({message: 'الرجاء تسجيل الدخول', show: true})
            }
        },

        getFormattedQuery(query) {
            var formattedQuery = ""

            for (const key in query) {
                if (Object.hasOwnProperty.call(query, key)) {
                    if (query[key]) {
                        if (Array.isArray(query[key])) {
                            for (let i = 0; i < query[key].length; i++) {
                                formattedQuery += key + "=" + query[key][i] + '&'
                            }
                        } else {
                            formattedQuery += key + "=" + query[key] + '&'
                        }
                    }
                }
            }

            formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
            return formattedQuery
        },
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler() {
                this.filterChipsData = this.data

                this.setDisplayTabNames()
            }
        }
    }
}