import { isObjectEmpty } from '@mixins/helpers'
export default {
    methods: {
        canSearchWithNoValue(query) {
            var queryObject = JSON.parse(JSON.stringify(query));
            delete queryObject['order']; 
            delete queryObject['pageNumber']; 
            delete queryObject['pageSize']; 
            delete queryObject['sortBy']; 
            delete queryObject['searchWithin'];
            delete queryObject['reloadId'] 

            return isObjectEmpty(queryObject) ? false : true
        },
    }
}